import styled from "styled-components";

export const StyledShoppingList = styled.ul`
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	padding: 0;
	margin: 20px 0 0 0;
	overflow-y: scroll;
	max-height: 440px;
	justify-content: center;
`;
