import React, { useContext, useEffect, useRef, useState } from "react";
import CategoryItem from "../../Component/CategoryItem";
import ResultItem from "../../Component/ResultItem";
import ShoppingListContext from "../../Store/ShoppingListContext";
import PageWrapper from "../../Element/PageWrapper";
import SearchInput from "../../Element/SearchInput";
import { ResultContainer, ResultList } from "./SearchPage.styles";

const SearchPage = () => {
	const ctx = useContext(ShoppingListContext);

	const [searchQuery, setSearchQuery] = useState("");
	const [resultList, setResultList] = useState(ctx.items);

	const searchInputRef = useRef();

	const showsCategoryTree = !searchQuery && !ctx.currentCategory;
	const showsResultList = searchQuery || ctx.currentCategory;

	useEffect(() => {
		if (searchQuery === "" && ctx.currentCategory) {
			setResultList(
				ctx.items.filter((entry) => entry.category === ctx.currentCategory)
			);
			return;
		}

		if (searchQuery !== "" && ctx.currentCategory) {
			setResultList(
				ctx.items
					.filter((entry) => entry.category === ctx.currentCategory)
					.filter((entry) =>
						entry.label
							.toLowerCase()
							.trim()
							.includes(searchQuery.toLowerCase().trim())
					)
			);
			return;
		}

		if (searchQuery !== "") {
			setResultList(
				ctx.items.filter((entry) =>
					entry.label
						.toLowerCase()
						.trim()
						.includes(searchQuery.toLowerCase().trim())
				)
			);
		}
	}, [searchQuery, ctx.currentCategory, ctx.items]);

	// useEffect(() => {
	// 	searchInputRef.current.focus();
	// }, []);

	const handleBackToCategories = () => {
		if (showsCategoryTree) {
			return;
		}
		setSearchQuery("");
		ctx.clearCategory();
		searchInputRef.current.focus();
	};

	const handleSelectCategory = (id) => {
		ctx.selectCategory(id);
	};

	const handleInputClear = () => {
		if (searchQuery === "") {
			return;
		}
		setSearchQuery("");
		searchInputRef.current.focus();
	};

	const handleToggleListStatus = (id) => {
		ctx.toggleListStatus(id);
	};

	return (
		<PageWrapper>
			<SearchInput
				type="text"
				placeholder="Produktsuche"
				value={searchQuery}
				setQuery={setSearchQuery}
				ref={searchInputRef}
				onClear={handleInputClear}
			/>
			<ResultContainer>
				{showsCategoryTree && (
					<ResultList flex="row">
						{ctx.categories.map((cat) => (
							<CategoryItem
								key={cat.id}
								label={cat.label}
								id={cat.id}
								onClick={handleSelectCategory}
								imageSrc={cat.imageSrc}
							/>
						))}
					</ResultList>
				)}
				{showsResultList && (
					<ResultList flex="column">
						{resultList.map((item) => (
							<ResultItem
								key={item.id}
								label={item.label}
								onClick={handleToggleListStatus}
								id={item.id}
								isOnList={item.isOnList}
								isInSeason={item.isInSeason}
							/>
						))}
					</ResultList>
				)}
			</ResultContainer>
		</PageWrapper>
	);
};

export default SearchPage;
