import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css/normalize.css";
import "./index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ShoppingListContextProvider } from "./Store/ShoppingListContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SearchPage from "./Page/SearchPage/SearchPage";
import ShoppingListPage from "./Page/ShoppingListPage";
import Navbar from "./Component/Navbar/Navbar";
import AppWrapper from "./Element/AppWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Router>
			<ShoppingListContextProvider>
				<AppWrapper>
					<Routes>
						<Route path="/" element={<ShoppingListPage />} />
						<Route path="/search" element={<SearchPage />} />
					</Routes>
					<Navbar />
				</AppWrapper>
			</ShoppingListContextProvider>
		</Router>
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
