import React from "react";
import { Item } from "./CategoryItem.styles";

const CategoryItem = ({ label, onClick, id, imageSrc }) => {
	return (
		<Item onClick={() => onClick(id)} imageSrc={imageSrc}>
			{label}
		</Item>
	);
};

export default CategoryItem;
