import styled, { css } from "styled-components";

export const ResultContainer = styled.div`
	margin-top: 20px;
	padding: 0 12px;
`;

export const ResultList = styled.ul`
	overflow-y: scroll;
	height: 440px;
	list-style: none;
	padding: 0;
	display: flex;
	row-gap: 10px;
	margin-top: 0;
	flex-direction: ${(props) => props.flex};

	${(props) =>
		props.flex === "row" &&
		css`
			flex-wrap: wrap;
			justify-content: space-between;
		`}
`;
