import styled from "styled-components";

export const StyledInput = styled.input`
	height: 40px;
	border-radius: 10px;
	padding: 5px 8px;
	font-size: 18px;
	line-height: 20px;
	border: 1px solid lightgray;
	width: 100%;
	outline: none;
	padding-inline-start: 45px;
	padding-inline-end: 45px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

	&:focus {
		border-color: purple;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

export const IconContainerOne = styled.div`
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	left: 12px;
	z-index: 10;
`;

export const IconContainerTwo = styled.div`
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	right: 12px;
	z-index: 10;
	cursor: pointer;
`;
