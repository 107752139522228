import { createContext, useReducer, useState } from "react";
import { Categories } from "./database";
import shoppingListReducer from "./shoppingListReducer";

const initialContext = {
	items: [],
	categories: [],
	currentCategory: null,
	loadItemsFromTemplate: () => {},
	saveItemsAsTemplate: (list) => {},
	toggleListStatus: (id) => {},
	toggleCrossedOff: (id) => {},
	clearCrossedOffItems: () => {},
	saveShoppingList: () => {},
	setShoppingList: (list) => {},
	clearCategory: () => {},
	selectCategory: (categoryId) => {},
	setNavbarVisibility: () => {},
	isNavbarVisible: true,
};

const initialState = {
	items: [],
	templateList: [],
	selectedCategory: null,
};

const ShoppingListContext = createContext(initialContext);

export const ShoppingListContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(shoppingListReducer, initialState);
	const [isNavbarVisible, setIsNavbarVisible] = useState(true);

	const handleCategorySelect = (categoryId) => {
		dispatch({ type: "HANDLE_CATEGORY_SELECT", payload: categoryId });
	};

	const handleClearCategory = () => {
		dispatch({ type: "CLEAR_CATEGORY" });
	};

	const handleToggleListStatus = (itemId) => {
		dispatch({ type: "TOGGLE_LIST_STATUS", payload: itemId });
		dispatch({ type: "SAVE_SHOPPING_LIST" });
	};

	const handleToggleCrossedOffStatus = (itemId) => {
		dispatch({ type: "TOGGLE_CROSSED_OFF", payload: itemId });
		dispatch({ type: "SAVE_SHOPPING_LIST" });
	};

	const handleClearCrossedOffItems = () => {
		dispatch({ type: "CLEAR_CROSSED_OFF" });
		dispatch({ type: "SAVE_SHOPPING_LIST" });
	};

	const handleOnShoppingListSave = () => {
		dispatch({ type: "SAVE_SHOPPING_LIST" });
	};

	const handleOnTemplateSave = () => {
		dispatch({ type: "SAVE_ITEMS_AS_TEMPLATE" });
	};

	const handleOnTemplateLoad = () => {
		dispatch({ type: "LOAD_ITEMS_FROM_TEMPLATE" });
		dispatch({ type: "SAVE_SHOPPING_LIST" });
	};

	const handleSetShoppingList = (list) => {
		dispatch({ type: "SET_SHOPPING_LIST", payload: list });
	};

	return (
		<ShoppingListContext.Provider
			value={{
				categories: Categories,
				items: state.items,
				currentCategory: state.selectedCategory,
				selectCategory: handleCategorySelect,
				clearCategory: handleClearCategory,
				toggleListStatus: handleToggleListStatus,
				toggleCrossedOff: handleToggleCrossedOffStatus,
				clearCrossedOffItems: handleClearCrossedOffItems,
				saveShoppingList: handleOnShoppingListSave,
				saveItemsAsTemplate: handleOnTemplateSave,
				loadItemsFromTemplate: handleOnTemplateLoad,
				setNavbarVisibility: setIsNavbarVisible,
				setShoppingList: handleSetShoppingList,
				isNavbarVisible,
			}}
		>
			{children}
		</ShoppingListContext.Provider>
	);
};

export default ShoppingListContext;
