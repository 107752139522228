import styled from "styled-components";

export const StyledNavbar = styled.nav`
	position: fixed;
	background-color: #eeeeee;
	width: 100%;
	height: 80px;
	bottom: 0;
	left: 0;
	border-radius: 20px 20px 0 0;
	box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.2);
	padding: 0 30px;
`;

export const NavbarContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: space-between;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const Label = styled.h3`
	margin: 5px 0 0 0;
	font-size: 10px;
	line-height: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
`;

export const Category = styled.h2`
	font-size: 18px;
	line-height: 20px;
	margin: 0;
	font-weight: 400;
	text-transform: uppercase;
	word-break: break-all;
`;
