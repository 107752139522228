import React from "react";
import { SeasonLabel, StyledResultItem } from "./ResultItem.styles";

const ResultItem = ({ label, id, onClick, isOnList, isInSeason }) => {
	return (
		<StyledResultItem onClick={() => onClick(id)} isOnList={isOnList}>
			{label}
			{isInSeason && <SeasonLabel>Regional</SeasonLabel>}
		</StyledResultItem>
	);
};

export default ResultItem;
