import React, { useContext } from "react";
import ShoppingListContext from "../../Store/ShoppingListContext";
import { Link, useLocation } from "react-router-dom";
import {
	IconContainer,
	StyledNavbar,
	NavbarContainer,
	Label,
	Category,
} from "./Navbar.styles";
import CtaButton from "../../Element/CtaButton";
import ShoppingCart from "../../assets/Icons/ShoppingCart";
import FileArrowOut from "../../assets/Icons/FileArrowOut";
import FileArrowIn from "../../assets/Icons/FileArrowIn";
import Pencil from "../../assets/Icons/Pencil";
import ArrowLeft from "../../assets/Icons/ArrowLeft";

const Navbar = () => {
	const ctx = useContext(ShoppingListContext);

	const location = useLocation();
	const category =
		ctx.categories.find((cat) => cat.id === ctx.currentCategory)?.label ?? "";
	const isSearchMenu = location.pathname === "/search";
	const isShoppingList = location.pathname === "/";

	return ctx.isNavbarVisible ? (
		<StyledNavbar>
			{isSearchMenu && (
				<Link to={"/"} onClick={() => ctx.clearCategory()}>
					<CtaButton>
						<ShoppingCart width="45px" height="45px" color="white" />
					</CtaButton>
				</Link>
			)}
			{isShoppingList && (
				<Link to={"/search"}>
					<CtaButton>
						<Pencil width="45px" height="45px" color="white" />
					</CtaButton>
				</Link>
			)}
			{isSearchMenu && ctx.currentCategory && (
				<NavbarContainer>
					<IconContainer onClick={() => ctx.clearCategory()}>
						<ArrowLeft width="40px" height="40px" color="goldenrod" />
						<Label>Kategorien</Label>
					</IconContainer>
					<Category>{category}</Category>
				</NavbarContainer>
			)}
			{isShoppingList && (
				<NavbarContainer>
					<IconContainer onClick={() => ctx.loadItemsFromTemplate()}>
						<FileArrowOut width="40px" height="40px" color="goldenrod" />
						<Label>Laden</Label>
					</IconContainer>
					<IconContainer onClick={() => ctx.saveItemsAsTemplate()}>
						<FileArrowIn width="40px" height="40px" color="goldenrod" />
						<Label>Speichern</Label>
					</IconContainer>
				</NavbarContainer>
			)}
		</StyledNavbar>
	) : null;
};

export default Navbar;
