import styled from "styled-components";

export const Item = styled.li`
	position: relative;
	border-radius: 15px;
	border: 1px solid lightgray;
	width: calc(50% - 7.5px);
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	line-height: 24px;
	text-align: center;
	color: white;
	z-index: 5;

	&::after {
		width: 100%;
		height: 100%;
		/* background: green, ; */
		/* background-blend-mode: lighten, normal; */
		background-image: url(${(props) => props.imageSrc});
		background-size: cover;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		border-radius: 15px;
		opacity: 1;
		z-index: 0;
	}
`;
