const shoppingListReducer = (state, { type, payload }) => {
	if (type === "HANDLE_CATEGORY_SELECT") {
		return {
			...state,
			selectedCategory: payload,
		};
	}

	if (type === "CLEAR_CATEGORY") {
		return {
			...state,
			selectedCategory: null,
		};
	}

	if (type === "TOGGLE_LIST_STATUS") {
		const foundItem = state.items.find((item) => item.id === payload);
		// TODO: Error Handling
		return {
			...state,
			items: state.items.map((entry) => {
				if (entry.id === foundItem.id) {
					return {
						...entry,
						isOnList: !entry.isOnList,
					};
				}
				return entry;
			}),
		};
	}

	if (type === "TOGGLE_CROSSED_OFF") {
		const foundItem = state.items.find((item) => item.id === payload);

		return {
			...state,
			items: state.items.map((entry) => {
				if (entry.id === foundItem.id) {
					return { ...entry, isCrossedOff: !entry.isCrossedOff };
				}
				return entry;
			}),
		};
	}

	if (type === "CLEAR_CROSSED_OFF") {
		return {
			...state,
			items: state.items.map((entry) => {
				if (entry.isCrossedOff) {
					return { ...entry, isOnList: false, isCrossedOff: false };
				}
				return entry;
			}),
		};
	}

	if (type === "SAVE_SHOPPING_LIST") {
		localStorage.setItem("currentList", JSON.stringify(state.items));
		localStorage.setItem("listCreatedAt", JSON.stringify(new Date()));
	}

	if (type === "SET_SHOPPING_LIST") {
		return {
			...state,
			items: payload,
		};
	}

	if (type === "SAVE_ITEMS_AS_TEMPLATE") {
		const templateItemsIds = state.items
			.filter((entry) => entry.isOnList)
			.map((entry) => entry.id);
		localStorage.setItem("templateList", JSON.stringify(templateItemsIds));
	}

	if (type === "LOAD_ITEMS_FROM_TEMPLATE") {
		const storageTemplateIds =
			JSON.parse(localStorage.getItem("templateList")) ?? [];
		// TODO: Error Message Handling
		if (!storageTemplateIds.length) {
			return state;
		}

		const updatedItems = state.items;
		storageTemplateIds.forEach((id) => {
			const foundEntry = updatedItems.find((entry) => entry.id === id);
			foundEntry.isOnList = true;
		});

		return {
			...state,
			items: updatedItems,
		};
	}

	return state;
};

export default shoppingListReducer;
