import { seasonProductIds as id } from "./database";

export const months = {
	JAN: 0,
	FEB: 1,
	MRZ: 2,
	APR: 3,
	MAI: 4,
	JUN: 5,
	JUL: 6,
	AUG: 7,
	SEP: 8,
	OKT: 9,
	NOV: 10,
	DEZ: 11,
};

const inSeason = [];
inSeason[months.JAN] = [
	id.CHAMPIGNONS,
	id.GRUENKOHL,
	id.LAUCH,
	id.ROSENKOHL,
	id.FELDSALAT,
	id.CHINAKOHL,
];
inSeason[months.FEB] = [
	id.CHAMPIGNONS,
	id.GRUENKOHL,
	id.LAUCH,
	id.ROSENKOHL,
	id.FELDSALAT,
	id.CHINAKOHL,
];
inSeason[months.MRZ] = [
	id.CHAMPIGNONS,
	id.LAUCH,
	id.ROSENKOHL,
	id.SPINAT,
	id.FELDSALAT,
];
inSeason[months.APR] = [
	id.CHAMPIGNONS,
	id.LAUCH,
	id.SPARGEL,
	id.SPINAT,
	id.FELDSALAT,
	id.RUCOLA,
];
inSeason[months.MAI] = [
	id.BLUMENKOHL,
	id.CHAMPIGNONS,
	id.KOHLRABI,
	id.LAUCH,
	id.FRUEHL_ZWIEBELN,
	id.RADIESCHEN,
	id.SPARGEL,
	id.SPINAT,
	id.ERDBEERE,
	id.RUCOLA,
	id.CHINAKOHL,
];
inSeason[months.JUN] = [
	id.BLUMENKOHL,
	id.BROKKOLI,
	id.ERBSEN,
	id.GURKE,
	id.KARTOFFELN,
	id.KOHLRABI,
	id.LAUCH,
	id.FRUEHL_ZWIEBELN,
	id.MOEHREN,
	id.RADIESCHEN,
	id.ROTKOHL,
	id.SPARGEL,
	id.WEISSKOHL,
	id.ZUCCHINI,
	id.HEIDELBEEREN,
	id.ERDBEERE,
	id.HIMBEEREN,
	id.KIRSCHEN,
	id.SALAT,
	id.RUCOLA,
	id.CHINAKOHL,
];
inSeason[months.JUL] = [
	id.AUBERGINE,
	id.BLUMENKOHL,
	id.GRUENE_BOHNEN,
	id.BROKKOLI,
	id.CHAMPIGNONS,
	id.ERBSEN,
	id.GURKE,
	id.KARTOFFELN,
	id.KOHLRABI,
	id.LAUCH,
	id.FRUEHL_ZWIEBELN,
	id.MOEHREN,
	id.PAPRIKA,
	id.RADIESCHEN,
	id.ROTKOHL,
	id.STAUDENSELLERIE,
	id.TOMATEN,
	id.WEISSKOHL,
	id.ZUCCHINI,
	id.ZWIEBEL,
	id.ROTE_BEETE,
	id.HEIDELBEEREN,
	id.ERDBEERE,
	id.HIMBEEREN,
	id.KIRSCHEN,
	id.SALAT,
	id.RUCOLA,
	id.CHINAKOHL,
];

inSeason[months.AUG] = [
	id.AUBERGINE,
	id.BLUMENKOHL,
	id.GRUENE_BOHNEN,
	id.BROKKOLI,
	id.CHAMPIGNONS,
	id.ERBSEN,
	id.GURKE,
	id.KARTOFFELN,
	id.KOHLRABI,
	id.KUERBIS,
	id.LAUCH,
	id.FRUEHL_ZWIEBELN,
	id.MAIS,
	id.MOEHREN,
	id.PAPRIKA,
	id.RADIESCHEN,
	id.ROTE_BEETE,
	id.ROTKOHL,
	id.STAUDENSELLERIE,
	id.TOMATEN,
	id.WEISSKOHL,
	id.ZUCCHINI,
	id.ZWIEBEL,
	id.APFEL,
	id.BIRNEN,
	id.HEIDELBEEREN,
	id.HIMBEEREN,
	id.KIRSCHEN,
	id.SALAT,
	id.RUCOLA,
	id.CHINAKOHL,
];
inSeason[months.SEP] = [
	id.AUBERGINE,
	id.BLUMENKOHL,
	id.GRUENE_BOHNEN,
	id.BROKKOLI,
	id.CHAMPIGNONS,
	id.GURKE,
	id.KARTOFFELN,
	id.KOHLRABI,
	id.KUERBIS,
	id.LAUCH,
	id.FRUEHL_ZWIEBELN,
	id.MAIS,
	id.MOEHREN,
	id.PAPRIKA,
	id.RADIESCHEN,
	id.ROTE_BEETE,
	id.ROTKOHL,
	id.SPINAT,
	id.STAUDENSELLERIE,
	id.TOMATEN,
	id.WEISSKOHL,
	id.ZUCCHINI,
	id.ZWIEBEL,
	id.APFEL,
	id.BIRNEN,
	id.HEIDELBEEREN,
	id.WEINTRAUBEN,
	id.SALAT,
	id.RUCOLA,
	id.CHINAKOHL,
];
inSeason[months.OKT] = [
	id.AUBERGINE,
	id.BLUMENKOHL,
	id.GRUENE_BOHNEN,
	id.BROKKOLI,
	id.CHAMPIGNONS,
	id.GURKE,
	id.KARTOFFELN,
	id.KOHLRABI,
	id.KUERBIS,
	id.LAUCH,
	id.FRUEHL_ZWIEBELN,
	id.MAIS,
	id.MOEHREN,
	id.PAPRIKA,
	id.RADIESCHEN,
	id.ROSENKOHL,
	id.ROTE_BEETE,
	id.ROTKOHL,
	id.SPINAT,
	id.STAUDENSELLERIE,
	id.TOMATEN,
	id.WEISSKOHL,
	id.ZUCCHINI,
	id.ZWIEBEL,
	id.APFEL,
	id.BIRNEN,
	id.WEINTRAUBEN,
	id.SALAT,
	id.FELDSALAT,
	id.CHINAKOHL,
];
inSeason[months.NOV] = [
	id.CHAMPIGNONS,
	id.GRUENKOHL,
	id.KUERBIS,
	id.LAUCH,
	id.ROSENKOHL,
	id.ROTE_BEETE,
	id.ROTKOHL,
	id.SPINAT,
	id.WEISSKOHL,
	id.APFEL,
	id.FELDSALAT,
	id.CHINAKOHL,
];
inSeason[months.DEZ] = [
	id.CHAMPIGNONS,
	id.GRUENKOHL,
	id.LAUCH,
	id.ROSENKOHL,
	id.FELDSALAT,
	id.CHINAKOHL,
];

export default function getSaisonalList(list) {
	const currentMonth = new Date().getMonth();

	const saisonList = list.map((entry) => ({ ...entry, isInSeason: false }));

	inSeason[currentMonth].forEach((entry) => {
		const foundItem = saisonList.find((item) => item.id === entry);
		foundItem.isInSeason = true;
	});

	return saisonList;
}
