import React, { useCallback, useContext, useEffect } from "react";
import ShoppingListItem from "../../Component/ShoppingListItem";
import ShoppingListContext from "../../Store/ShoppingListContext";
import ShoppingList from "../../Component/ShoppingList";
import PageWrapper from "../../Element/PageWrapper";
import { Items } from "../../Store/database";
import getSaisonalList from "../../Store/seasonCalendar";
import {
	Badge,
	IconContainer,
	InfoBar,
	Label,
	NoItemsLabel,
	Title,
} from "./ShoppingListPage.styles";
import TrashCan from "../../assets/Icons/TrashCan";

const ShoppingListPage = () => {
	const ctx = useContext(ShoppingListContext);

	const handleInitializeItems = useCallback(() => {
		const storageItems = JSON.parse(localStorage.getItem("currentList"));

		if (storageItems === null || storageItems.length !== Items.length) {
			const list = getSaisonalList(Items);
			ctx.setShoppingList(list);
			return;
		}

		const currentMonth = new Date().getMonth();
		const lastUpdateMonth =
			new Date(
				JSON.parse(localStorage.getItem("listCreatedAt"))
			).getMonth() ?? null;

		if (currentMonth !== lastUpdateMonth) {
			const storageList = getSaisonalList(storageItems);
			ctx.setShoppingList(storageList);
			return;
		}

		ctx.setShoppingList(storageItems);
	}, [ctx.items.length]);

	useEffect(() => {
		console.log("called");
		handleInitializeItems();
	}, [handleInitializeItems]);

	const shoppingList = ctx.items.filter((entry) => entry.isOnList);
	const itemCount = shoppingList.length;

	return (
		<PageWrapper>
			<InfoBar>
				<Badge>{itemCount}</Badge>
				<Title>
					Sh&oslash;pping<sup>TM</sup>
				</Title>
				<IconContainer onClick={() => ctx.clearCrossedOffItems()}>
					<TrashCan width="20px" height="20px" color="white" />
					<Label>Streich Weg</Label>
				</IconContainer>
			</InfoBar>
			{shoppingList.length > 0 ? (
				<ShoppingList>
					{shoppingList.map((item) => (
						<ShoppingListItem
							label={item.label}
							onClick={ctx.toggleCrossedOff}
							id={item.id}
							key={item.id}
							isCrossedOff={item.isCrossedOff}
						/>
					))}
				</ShoppingList>
			) : (
				<NoItemsLabel>Keine Einträge</NoItemsLabel>
			)}
		</PageWrapper>
	);
};

export default ShoppingListPage;
