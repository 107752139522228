import styled from "styled-components";

export const InfoBar = styled.div`
	border-radius: 5px;
	background-color: purple;
	height: 60px;
	display: flex;
	padding: 5px 15px;
	justify-content: space-between;
	align-items: center;
`;

export const IconContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const Label = styled.h3`
	margin: 5px 0 0 0;
	font-size: 10px;
	line-height: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: white;
`;

export const NoItemsLabel = styled.h1`
	text-align: center;
`;

export const Badge = styled.div`
	padding: 10px;
	border-radius: 50%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	color: purple;
	font-weight: bold;
	height: 35px;
	width: 35px;
`;

export const Title = styled.h2`
	color: white;
	font-weight: normal;
	font-size: 25px;
	line-height: 27px;
	color: goldenrod;
`;
