import React from "react";
import { StyledItem } from "./ShoppingListItem.styles";

const ShoppingListItem = ({ label, id, onClick, isCrossedOff }) => {
	return (
		<StyledItem isCrossedOff={isCrossedOff} onClick={() => onClick(id)}>
			{label}
		</StyledItem>
	);
};

export default ShoppingListItem;
