import React, {
	forwardRef,
	useContext,
	useImperativeHandle,
	useRef,
} from "react";
import {
	InputContainer,
	StyledInput,
	IconContainerOne,
	IconContainerTwo,
} from "./SearchInput.styles";

import MagnifyingGlass from "../../assets/Icons/MagnifyingGlass";
import CircleX from "../../assets/Icons/CircleX";
import ShoppingListContext from "../../Store/ShoppingListContext";

const SearchInput = forwardRef(
	({ type, placeholder, setQuery, value, onClear }, ref) => {
		const ctx = useContext(ShoppingListContext);
		const inputRef = useRef();
		const handleOnChange = ({ target }) => {
			setQuery(target.value);
		};

		useImperativeHandle(ref, () => ({
			focus: () => inputRef.current.focus(),
		}));

		const handleOnFocus = () => {
			ctx.setNavbarVisibility(false);
		};

		const handleOnBlur = () => {
			ctx.setNavbarVisibility(true);
		};

		return (
			<InputContainer>
				<IconContainerOne>
					<MagnifyingGlass height="22px" width="22px" color="darkgray" />
				</IconContainerOne>
				<StyledInput
					placeholder={placeholder}
					type={type}
					onChange={handleOnChange}
					value={value}
					ref={inputRef}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
				/>
				<IconContainerTwo onClick={onClear}>
					<CircleX height="22px" width="22px" color="dimgrey" />
				</IconContainerTwo>
			</InputContainer>
		);
	}
);
export default SearchInput;
