import styled from "styled-components";

export const ButtonContainer = styled.button`
	width: 90px;
	height: 90px;
	border-radius: 50%;
	background-color: purple;
	position: absolute;
	top: 0;
	z-index: 100;
	transform: translateY(-40%);
	border: none;
	margin: 0 auto;
	left: 0;
	right: 0;
`;
