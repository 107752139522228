import styled, { css } from "styled-components";

export const StyledResultItem = styled.li`
	cursor: pointer;
	font-size: 16px;
	line-height: 18px;
	height: 30px;
	padding: 5px 10px;
	border-radius: 3px;
	border: 1px solid lightgray;
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
	padding-inline-start: 20px;
	color: #666666;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	width: calc(100% - 15px);

	${(props) =>
		props.isOnList &&
		css`
			border-bottom-color: purple;
			color: black;
		`}
`;

export const SeasonLabel = styled.span`
	color: green;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: block;
`;
