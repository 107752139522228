import styled from "styled-components";

export const StyledItem = styled.li`
	text-decoration: ${(props) =>
		props.isCrossedOff ? "line-through" : "none"};
	user-select: none;
	text-align: center;
	font-size: 22px;
	height: 40px;
	line-height: 24px;
	text-decoration-color: #666666;
	color: ${(props) => (props.isCrossedOff ? "#666666" : "black")};
	border: 2px solid
		${(props) => (props.isCrossedOff ? "transparent" : "purple")};
	padding: 5px 10px;
	border-radius: 5px;
`;
